<template>
  <b-row class="mt-2 mb-2">
    <b-col md="12">
      <b-overlay :show="isLoading" rounded="lg">
        <b-list-group @click="getStats()">
          <b-list-group-item href="#"   class="flex-column align-items-start">
            <div class="d-flex" style="justify-content: space-between">
            <h5>В очереди: {{stat.created}}</h5><small>{{ stat.update }} </small>
            </div>
<!--            <div class="d-flex" style="justify-content: space-around">-->
<!--            <div>-->
<!--              <b-badge class="mr-1" variant="info">ip</b-badge>-->
<!--              <b-badge class="mr-1" variant="link">{{stat.ip}}</b-badge>-->
<!--            </div>-->
<!--            <div>-->
<!--              <b-badge class="mr-1" variant="primary">ooo</b-badge>-->
<!--              <b-badge class="mr-1" variant="link">{{stat.ooo}}</b-badge>-->
<!--            </div>-->
<!--            <div>-->
<!--              <b-badge class="mr-1" variant="warning">vipl</b-badge>-->
<!--              <b-badge class="mr-1" variant="link">{{stat.vipl}}</b-badge>-->
<!--            </div>-->
<!--              <div>-->
<!--                <b-badge class="mr-1" variant="danger">error</b-badge>-->
<!--                <b-badge class="mr-1" variant="link">{{stat.error}}</b-badge>-->
<!--              </div>-->
<!--            </div>-->
            <hr>
            <div class="d-flex" style="justify-content: space-around">
              <div style="width: 50%;justify-content: space-evenly">
                <b-button class="mb-2" block @click="getXlsx('ip')" size="sm" variant="info" title="скачать файл для ручной заливки">  xlsx ip ({{stat.ip}})<b-icon-file-earmark-excel /></b-button>
                <b-button @click="clearQueue('ip')" size="sm" variant="info" title="Цены загружены">Очистить</b-button>
              </div>
              <div style="width: 50%">
                <b-button class="mb-2" block @click="getXlsx('ooo')" size="sm" variant="primary" title="скачать файл для ручной заливки">   xlsx ооо ({{stat.ooo}})<b-icon-file-earmark-excel /></b-button>
                <b-button @click="clearQueue('ooo')" size="sm" variant="primary" title="Цены загружены">Очистить</b-button>

              </div>
              <div style="width: 50%">
                <b-button class="mb-2" block @click="getXlsx('vipl')" size="sm" variant="warning" title="скачать файл для ручной заливки">   xlsx vipl ({{stat.vipl}})<b-icon-file-earmark-excel /></b-button>
                <b-button  @click="clearQueue('vipl')" size="sm" variant="warning" title="Цены загружены">Очистить</b-button>
              </div>
            </div>
            <hr>

            <div class="d-flex" style="justify-content: space-between">
<!--              <b-button :disabled="isProcessQueue"  @click="getAuto()" size="sm" variant="primary" title="скачать файл для ручной заливки">-->
<!--                <b-spinner small v-if='isProcessQueue'></b-spinner>-->
<!--                Обработать через WB API-->
<!--              </b-button>-->
<!--              <b-button :disabled="this.stat.created<0"  @click="clearQueue()" size="sm" variant="danger" title="очистить очередь">-->
<!--                <b-icon-trash2 />-->
<!--                Очистить очередь-->
<!--              </b-button>-->
            </div>

            <b-progress v-if="isProcessQueue" :value="curProgress" :max="maxProgress" variant="success" striped :animated="true" class="mt-2"></b-progress>
            <div class="d-flex" style="justify-content: flex-end" v-if="isProcessQueue" >
              {{curProgress}} из {{maxProgress}} {{(curErrors>0) ? `(ошибок: ${curErrors})` : ''}}
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>

    </b-col>
  </b-row>
</template>
<script>

export default {
  name: 'pp-main-widget-price-file',
  props: [ ],
  data: () => ({
    isLoading: true,
    isProcessQueue: false,
    maxProgress: 0,
    curProgress: 0,
    curErrors: 0,
    stat: {
      created: 0,
      error: 0,
      ip: 0,
      ooo: 0,
      vipl: 0,
      update: 0
    },
  }),
  methods: {
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    },
    getStats: function () {
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-price/stat`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.stat = resp.item

              this.isLoading = false
              const date = new Date()
              this.statsUpd =  date
            }
          })
          .catch(err => {
            console.log(err)
            this.isLoading = false
          })
    },
    getQueueState: function () {
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-price/stateQueue`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.curProgress = resp.item.result.length + resp.item.errors.length +1
              this.curErrors = resp.item.errors.length
              this.maxProgress =  resp.item.max //+ resp.item.error.length
              this.isProcessQueue = true
            } else {
              this.isProcessQueue = false
            }
          })
          .catch(err => {
            console.log(err)
            this.isLoading = false
          })
    },
    getAuto: function () {

      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-price/queue`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) console.log('ok') //this.isProcessQueue = true
          })
          .catch(err => {
            console.log(err)
            this.isLoading = false
          })
    },
    clearQueue: function (seller) {
       this.stat = {
         created: 0,
         error: 0,
         ip: 0,
         ooo: 0,
         vipl: 0,
         update: 0
       }
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-price/clearQueue/${seller}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) alert('очередь очизениа')
          })
          .catch(err => {
            alert('очередь  не очизениа')
            console.log(err)
          })
    },
    getXlsx: function (seller) {
      window.location = `http://localhost:5715/api/pp/pp-price/file/${seller}`
    }
  },
  watch: {
    // 'stat.created': function () {
    //   this.curProgress =  this.maxProgress - this.stat.created
    // },
  },
  mounted() {
    this.getStats(), this.getQueueState()
    // setInterval(() => this.getStats(), 5000)
    setInterval(() => this.getQueueState(), 5000)


  }
}
</script>
