<template>
  <b-overlay :show="isLoading" rounded="sm">
    <template v-if="salePrice">
      <b-overlay :show="onQueue.salePrice>0" rounded="sm" no-center>
        <template #overlay>
          <div style="margin-top: 3px;" title="цена сейчас на вб -> Цена в очереди ">сейчас: {{salePrice}} -> ждём: {{onQueue.salePrice}}</div>
        </template>
        <div style="display:flex;justify-content: flex-start;gap:20px;border:0px solid #000;">
         <b-form inline style="min-width: 280px;display: flex;justify-content: flex-start;">
           <label style="padding:0 5px" :for="`main_pirce_input-${artwb}`">цена:</label><b-form-input :id="`main_pirce_input-${artwb}`" v-model="salePrice" type="number" title="Цена на сайте"></b-form-input>
          <b-form-input disabled type="number" v-model="dohod" :class="{'text-danger': dohod<MIN_DOHOD}" :title="`${dohod} (доход) = ${salePrice} (цена на сайте) - ${Math.ceil((this.salePrice/100)*this.komsaPercent)} (комса wb ${komsaPercent}%) - ${zakup} (закуп) - 55 (логистика)`"></b-form-input>
          <b-form-input type="number" v-model="komsaPercent" title="% комсы вб"></b-form-input>
          <b-button size="sm" variant="success" @click="setToWbArtWb()" title="Загрузить цену">Ок</b-button>
          <b-icon v-if="curPromo != ''" icon="x-diamond-fill" scale="1" variant="success" :title="curPromo"></b-icon>
        </b-form>
        <b-form inline style="min-width: 280px;display: flex;justify-content: flex-start" v-if="promoPrice">
          <label style="padding:0 5px" :for="`promo_pirce_input-${artwb}`">промо:</label><b-form-input :id="`promo_pirce_input-${artwb}`" disabled type="number" :value="promoPrice" title="Цена под акцию вб">243</b-form-input>
          <b-form-input disabled type="number" v-model="promoDohod" :class="{'text-danger': dohod<MIN_DOHOD}" :title="`${promoDohod} (доход) = ${promoPrice} (цена под акцию) - ${Math.ceil((this.promoPrice/100)*this.promoKomsa)} (комса wb ${promoKomsa}%) - ${zakup} (закуп) - 55 (логистика)`"></b-form-input>
          <b-form-input type="number" v-model="promoKomsa" title="% комсы вб"></b-form-input>
          <b-button  @click="setPromoToWbArtWb()" size="sm" variant="info" title="Применить промо">Ок</b-button>
        </b-form>
        </div>
      </b-overlay>
    </template>
    <template v-else>
      <b-button block @click="getPriceForArt()" size="sm" variant="info" title="Не удалось загрузить акуальную цену">Обновить <b-icon-arrow-clockwise /></b-button>

    </template>

  </b-overlay>
</template>
<script>
export default {
  name: 'pp-main-curprice',
  props: ["artwb", "zakup", "seller","newsale"],
  data: () => ({
    isLoading: true,
    komsaPercent: 10,
    salePrice: 0,
    curPromo: '',
    MIN_DOHOD: 150,
    promoPrice: 0,
    promoKomsa: 10,
    onQueue: {
      salePrice: 0,

    }
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },

    setToWbArtWb() {
      fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-price/art_wb`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({art_wb:this.artwb, seller:this.seller, salePrice:this.salePrice}),
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              // this.onQueue=true
              this.alertMsg('Загружаем цену | Успешно', `Новые цены в очереди на заливку на вб, id: ${resp.item._id}`, 'success')
            }
            if (!resp.status) this.alertMsg('Загружаем цену | Ошибка', `ошибка сервера ${JSON.stringify(resp.message)}`, 'danger')
          })
    },
    setPromoToWbArtWb() {
      fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-price/art_wb`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({art_wb:this.artwb, seller:this.seller, salePrice:this.promoPrice}),
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            // if (resp) alert('добавлен')
            if (resp.status) {
              // this.onQueue=true
              this.alertMsg('Загружаем цену | Успешно', `Новые цены в очереди на заливку на вб, id: ${resp.item._id}`, 'success')
            }
            if (!resp.status) this.alertMsg('Загружаем цену | Ошибка', `ошибка сервера ${JSON.stringify(resp.message)}`, 'danger')
          })
    },
    getPriceForArt() {
     return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-info/form/${this.artwb}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
       signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.salePrice = resp.item.salePrice
              this.curPromo = resp.item.curPromo
              this.promoPrice = resp.item.promoPrice
              this.promoKomsa = resp.item.promoKomsa
              this.onQueue = resp.item.onQueue
            }
            if (!resp.status) this.alertMsg('Загружаем цену и промо | Ошибка', `ошибка загрузки цен и промо ${JSON.stringify(resp.message)}`, 'danger')
          })
      // .catch(err => this.alertMsg('Загружаем цену и промо | Ошибка', `ошибка сервера ${JSON.stringify(err)}`, 'danger'))
    }
  },
  beforeDestroy() {
    this.controller.abort();
  },
  async mounted() {
    this.controller = new AbortController();
    this.signal = this.controller.signal;
      this.getPriceForArt().then(()=>{
        this.isLoading = false
      })

  },
  watch: {
   'newsale': function () {
     this.salePrice = this.newsale
   }
  },
  computed: {
    dohod: function () {
      return  Math.ceil(this.salePrice - (this.salePrice/100)*this.komsaPercent - this.zakup-55)
    },
    promoDohod: function () {
      return  Math.ceil(this.promoPrice - (this.promoPrice/100)*this.promoKomsa - this.zakup-55)
    },
  }
}
</script>
<style scoped>
.btn.btn-sm {
margin: 1px 2px;
  padding: 0 5px;
}

input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
   width: 60px;
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>