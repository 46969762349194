<template>
  <b-row class="mt-2 mb-2">
    <b-col md="12">
      <b-overlay :show="isLoading" rounded="lg">
        <b-list-group >
          <b-list-group-item  class="flex-column align-items-start">
            <h5>Файл промо вб</h5>
            <div class="d-flex" style="justify-content: space-between">
              <b-form-file
                  v-model="file1"
                  placeholder="файлы промо с вб"
                  drop-placeholder="Перетаскивайте сюда..."
                  @input="addPromo()"
                  multiple
                  accept=".xlsx"
              ></b-form-file>
              <b-input v-model="promoName" />
            </div>
            <hr>
            <b-progress v-if="isProcessQueue" :value="curProgress" :max="maxProgress" variant="success" striped :animated="true" class="mt-2"></b-progress>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>

    </b-col>
  </b-row>
</template>
<script>

export default {
  name: 'pp-main-widget-upload-promo-file',
  props: [ ],
  data: () => ({
    isLoading: false,
    isProcessQueue: false,
    file1: null,
    promoName: ''
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    },
    async addPromo() {
      this.alertMsg('Загрузка файлов', 'Загружаем промо файлы','info')
      const formData = new FormData()
      formData.append('promoName', this.promoName)
      for (let file of this.file1) {
        formData.append('files', file, file.name)
      }
      console.log(formData)
      fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-promo/addpromo`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        body: formData,
        headers: {'dildo-token': this.$cookies.get("dildo-token")}
      })
          .then(resp => resp.json())
          .then(resp=>{
            if(resp.status) this.alertMsg('Загрузка файлов', 'Успешно загрузились','success')
            if (!resp.status) this.alertMsg('Ошибка загрузки файлов', `Ошибка сервера, попробуйте ещё раз ${resp.message}`, 'danger')
          })
          .catch(err=>{
            this.alertMsg('Ошибка загрузки файлов', `Ошибка сервера, попробуйте ещё раз \n${err}`, 'danger')
          })

      // this.updReview(_id)

    },
  },
  watch: {
    // 'stat.created': function () {
    //   this.curProgress =  this.maxProgress - this.stat.created
    // },
  },
  mounted() {
    // this.getStats(), this.getQueueState()
    // setInterval(() => this.getStats(), 6000)
    // setInterval(() => this.getQueueState(), 3000)


  }
}
</script>
