var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"pr-0",attrs:{"sm":"4"}},[_c('b-form-input',{attrs:{"type":"number","title":"Цена на сайте"},model:{value:(_vm.salePrice),callback:function ($$v) {_vm.salePrice=$$v},expression:"salePrice"}})],1),_c('b-col',{staticClass:"pr-0 pl-0",attrs:{"sm":"3"}},[_c('b-form-input',{attrs:{"disabled":"","type":"number","title":(_vm.dohod + " (доход) = " + _vm.salePrice + " (цена на сайте) - " + (Math.ceil((this.salePrice/100)*this.komsaPercent)) + " (комса wb " + _vm.komsaPercent + "%) - " + (_vm.item.arts[0].zakup) + " (закуп) - 55 (логистика)")},model:{value:(_vm.dohod),callback:function ($$v) {_vm.dohod=$$v},expression:"dohod"}})],1),_c('b-col',{staticClass:"pr-0 pl-0",attrs:{"sm":"2"}},[_c('b-form-input',{attrs:{"type":"number","title":"% комсы вб"},model:{value:(_vm.komsaPercent),callback:function ($$v) {_vm.komsaPercent=$$v},expression:"komsaPercent"}})],1),_c('b-col',{staticClass:"pr-0 pl-1",attrs:{"sm":"3"}},[(_vm.veryMinPrice>0)?_c('b-icon-cash',{staticClass:"mr-1",attrs:{"variant":"danger","animation":"throb","title":("Есть артикулы с доходом <" + _vm.MIN_DOHOD + " руб.")}}):_vm._e(),_c('nobr',[(_vm.wb.reduce(function (t, ref) {
	var curPromo = ref.curPromo;

	return t+curPromo.slice(0,1).length;
},0)>0)?_c('b-icon',{attrs:{"icon":"x-diamond-fill","variant":"success","title":'В акции артикулов: ' + _vm.wb.reduce(function (t, ref) {
	var curPromo = ref.curPromo;

	return t+curPromo.slice(0,1).length;
},0) + ' из ' + _vm.wb.length}}):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',[(_vm.artsOnQueue==0)?_c('b-badge',{staticClass:"mr-2",attrs:{"href":"#","size":"sm","variant":"success"},on:{"click":function($event){return _vm.setToWbAllModel()}}},[_vm._v("Применить")]):_vm._e(),_vm._v(" "+_vm._s(Array.from(new Set(_vm.wb.map(function (e){ return e.salePrice; }))))+" "),(_vm.artsOnQueue>0)?_c('span',[_vm._v("ждут проверки цен: "+_vm._s(_vm.artsOnQueue))]):_vm._e(),_c('br'),(_vm.wbPromo.length>0 && _vm.artsOnQueue==0)?_c('b-badge',{staticClass:"mr-2",attrs:{"href":"#","size":"sm","variant":"info"},on:{"click":function($event){return _vm.setPromoAll(0)}}},[_vm._v("Промо для всех ("+_vm._s(_vm.wbPromo.length)+")")]):_vm._e(),(_vm.wbPromo.filter(function (e){ return e.promoDohod>=100; }).length>0 && _vm.artsOnQueue==0)?_c('b-badge',{staticClass:"mr-2",attrs:{"href":"#","size":"sm","variant":"info"},on:{"click":function($event){return _vm.setPromoAll(100)}}},[_vm._v("Промо доход>100 ("+_vm._s(_vm.wbPromo.filter(function (e){ return e.promoDohod>=100; }).length)+")")]):_vm._e()],1)],1)],1)],1):_c('b-row',[_c('b-col',[_c('b-skeleton')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }