<template>
  <div :class="getErrClass(vip, wb)" class="shadow rounded" style="padding:5px 10px">
<b-row>
<b-col class="mb-2">
  <div style="display:flex;" class="mb-2">
    <a class="mr-2" :href="'https://www.wildberries.ru/catalog/' + item.art_wb + '/detail.aspx?targetUrl=XS'" target="_blank" style="font-size:18px;color:#000">
       {{item.vipName}}
    </a>
<div>
  <template v-if="vip">
    VIP:
    <span :title="`Остатки на наших складах в мск`" class="text-white pl-1 pr-1 mr-1" :class="getBgClass(vip.qty)" style="cursor: default">{{vip.qty}}</span>
    <span :title="`Остатки у поставщиков в мск`" class="text-white pl-1 pr-1 mr-2" :class="getBgClass(vip.qtyWh)" style="cursor: default">{{vip.qtyWh}}</span>
  </template>
  <template v-else>
    VIP:
    <span :title="`Остатки на наших складах | не удалось загрузить`" class="text-white pl-1 pr-1 mr-1 bg-warning" style="cursor: default">-</span>
    <span :title="`Остатки у поставщиков | не удалось загрузить`" class="text-white pl-1 pr-1 mr-2 bg-warning" style="cursor: default">-</span>

  </template>
  <template v-if="wb">
    WB:
    <span title="Остатки на складе вб" class="text-white pl-1 pr-1 mr-1" :class="getBgClass(wb.qty.fbo)" style="cursor: default">{{ wb.qty.fbo }}</span>
    <span title="Транслируем на вб" class="text-white pl-1 pr-1 mr-2" :class="getBgClass(wb.qty.fbs)" style="cursor: default">{{ wb.qty.fbs }}</span>
  </template>
  <template v-else>
    WB:
    <span title="Остатки на складе вб | не удалось загрузить инфу с вб" class="text-white pl-1 pr-1 mr-1 bg-warning" style="cursor: default">-</span>
    <span title="Транслируем на вб | не удалось загрузить инфу с вб" class="text-white pl-1 pr-1 mr-2 bg-warning" style="cursor: default">-</span>
  </template>

</div>
  </div>

  <b-button size="sm" variant="primary"  title="Создать клон карточки" class="mr-2">+ Клон</b-button>
  <b-button size="sm" variant="primary"  title="Добавить в выкупы и написать отзыв" class="mr-2 ">+ Выкуп</b-button>
<!--  <b-button size="sm" variant="primary" @click="" title="Установить минимальную цену" class="mr-2 ">Мин. цена</b-button>-->
</b-col>
  <b-col class="mb-2" sm="12">
    <pp-main-me-and-gays
        :artwb="item.art_wb" :zakup="item.zakup"
        @setmin="newPrice=$event"

  /></b-col>

  <b-col>
    <pp-main-curprice :artwb="item.art_wb"  :zakup="item.zakup" :seller="item.seller" :newsale="newPrice" :key="item.art_wb" class="mb-2"/>

  </b-col>
</b-row>

  </div>

</template>
<style scoped>
.btn.btn-sm {
  margin: 1px 2px;
  padding: 0 5px;
}
.sales_value {
  border: 1px solid #e7e7e7;
}
.bg-danger-err {
  background-color: rgba(220, 53, 69, 0.07) !important;
}

input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  width: 60px;
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>
<script>


import PpMainCurprice from "@/ProductsAndPrice/cols/PPmain-curprice";
// import PpMainGays from "@/ProductsAndPrice/cols/PPmain-gays";
// import PpMainPriceFormRowSales from "@/ProductsAndPrice/PPmain-priceform-row-sales";
import PpMainMeAndGays from "@/ProductsAndPrice/cols/PPmain-MeAndGays";
export default {
name: 'pp-main-price-form-row',
props: ["item", "wb", "vip"],
  components:{ PpMainCurprice, PpMainMeAndGays },
data: () => ({
curShow: "Цены и промо",
  info: null,
  newPrice: null,
}),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    },
    getErrClass: function(vip, wb) {
      if (!vip) return ''
      if (vip.qty+vip.qtyWh>0 && wb.qty.fbs<=0) return 'bg-danger-err'
    return 'bg-light'

    },
    sendCloneCard: function () {
      fetch(`http://${process.env.VUE_APP_API_SERVER}:${process.env.VUE_APP_API_PORT}/pp/pp-clone/addnewclone`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({arts_wb:[this.item.arts_wb]}),
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.alertMsg('Создать клон | Успешно', `Новые клон в очереди, id: ${resp.item._id}`, 'success')
            }
            if (!resp.status) this.alertMsg('Создать клон | Ошибка', `ошибка сервера ${JSON.stringify(resp.message)}`, 'danger')
          })
    }
  },
async mounted() {
  this.controller = new AbortController();
  this.signal = this.controller.signal;
},
  watch: {

  }
}
</script>
