<template>
  <div style="display: none">

    <button @click.prevent="addData">add</button>
    <canvas id="myChart" height="100"></canvas>
  </div>
</template>

<script>

export default {
  name: "pp-charts",
  props: ['art_wb'],
  data: () => ({
    data: {
      labels: ['Red', 'Blue', 'Blue', 'Blue', 'Blue'],
      datasets: [
          {
        label:'neo',
        data: [21,7, 16,31,40],
        borderColor: ['rgba(0,123,255,1)'],
        backgroundColor: ['rgba(0,123,255,.1)'],
        // tension: 0.4,
      },
        {
          label:'vipl',
          data: [31,37, 76,31,50],
          borderColor: ['rgba(255,193,7,1)'],
          backgroundColor: ['rgba(255,193,7,0.14)'],
        }]
    },
    chart: ''
  }),
  methods: {
    clearCharts: function () {
      this.data.labels=[]
      this.data.datasets.find(e=>e.label=='vipl').data =[]
      this.data.datasets.find(e=>e.label=='neo').data =[]
    },
    addData: function () {
      if (this.data.labels.length>=30) this.clearCharts()
      this.data.datasets.find(e=>e.label=='vipl').data.push(Math.random()*100)
      this.data.datasets.find(e=>e.label=='neo').data.push(Math.random()*100)
      this.data.labels.push('olol')
      this.chart.update();
      // this.rivalCharts()
    },

    rivalCharts: async function() {
      const Chart = require("chart.js")
      const ctx = document.getElementById('myChart').getContext('2d');
      // console.log(this.data)
      this.chart = new Chart(ctx, {
        type: 'line',
        data: this.data,
        options: {
          hoverRadius: 12,
          hoverBackgroundColor: 'yellow',
          interaction: {
                  mode: 'nearest',
                  intersect: false,
                  axis: 'x'
                },
        }
      });
    }
  },
  async mounted() {
    this.rivalCharts()
  },
  async updated() {
    // this.rivalCharts()
  }
}
</script>

<style scoped>
.row {
  height: 260px;
  overflow: auto;
}
</style>