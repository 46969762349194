<template>
    <b-row>
        <b-col>
          <b-table
              :items="gays"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              responsive="sm"
              :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center">
                <b-spinner variant="primary" label="loading..."></b-spinner>
              </div>
            </template>
            <template #cell(seller)="{item}">
              <a :class="getBgRowClass(item.art_wb)" :href="'https://www.wildberries.ru/catalog/' + item.art_wb + '/detail.aspx?targetUrl=XS'" target="_blank">
                {{item.seller}} <b-icon icon="box-arrow-up-right"></b-icon>
              </a>
            </template>
<!--          </b-table>-->

<!--          <b-table striped hover :items="gays" :fields="fields" stacked="md" responsive table-class="mb-0"  style="font-size: 10px">-->

            <template #cell(salePrice)="{item}">
              <span title="Цена на сайте (доход с учётом нашей закупки и комсы 10%)" style="font-size: 14px">{{item.salePrice}} ({{Math.ceil(item.salePrice - (item.salePrice/100)*10 - zakup-55)}})</span>
              <b-badge class="ml-1" variant="info" v-if="findMinPrice(item.salePrice, gays.map(e=>e.salePrice))" @click.prevent="$emit('setmin', item.salePrice)" style="cursor:pointer">Мин. цена</b-badge>
            </template>
            <template #cell(qty)="{item}">
              <span title="Остатки на складе вб" class="text-white pl-1 pr-1 mr-1" :class="getBgClass(item.qty.fbo)">{{ item.qty.fbo }}</span>
              <span title="Транслируют на вб" class="text-white pl-1 pr-1 mr-2" :class="getBgClass(item.qty.fbs)">{{ item.qty.fbs }}</span>
            </template>
            <template #cell(rating)="{item}">
              <span v-if="item.feedbackCount > 0">
              <b-icon-star-fill v-for="r of item.rating" :key="'key-1'+r"/><b-icon-star  v-for="r of 5-item.rating" :key="'key-12'+r" />
              {{item.feedbackCount}} </span>
              <span v-else> нет отзывов
               </span>
            </template>
            <template #cell(position) >
              0
            </template>
            <template #cell(sales)="{item}">
              <div v-if="item.sales.length>0" class="d-flex" style="justify-content: flex-start;border: 0px solid #f0f">
                <div class="sales_value" v-for="ofDate of item.sales.slice(0,7)" :key="ofDate.date+item.art_wb">
                  <span class="text-monospace" :title="`${ofDate.date}\nПродаж: ${ofDate.sales}\nНа сумму: ${ofDate.sales*item.salePrice}\nДоход: ${Math.ceil((item.salePrice - (item.salePrice/100)*10 - zakup-55)*ofDate.sales)}`"> {{ofDate.sales}}</span>
                </div>
              </div>
<!--              <div class="d-flex" style="justify-content: flex-start;border: 0px solid #f0f">-->
<!--                <div class="salesOfTwoWeek_value" v-for="ofDate of item.sales" :key="ofDate.date+item.art_wb">-->
<!--                  <span :title="`${ofDate.date}\nПродаж: ${ofDate.sales}\nНа сумму: ${ofDate.sales*item.salePrice}\nДоход: ${Math.ceil((item.salePrice - (item.salePrice/100)*10 - zakup-55)*ofDate.sales)}`"> {{ofDate.sales}}</span>-->
<!--                </div>-->
<!--              </div>-->
            </template>
          </b-table>
        </b-col>
    </b-row>
</template>
<style scoped>
.sales_value {
  border: 1px solid #ccc;
  padding: 0px;
  margin: 0px;
  width: 30px;
  text-align: center;
  cursor: default;
}
</style>
  <script>

export default {
  name: 'pp-main-me-and-gays',
  components: { },
  props: ["artwb", "zakup"],
  data: () => ({
    sortBy: 'age',
    sortDesc: false,
    isShow: false,
    isLoading: false,
    gays: [],
    fields: [
      {key: "seller", sortable: true, label: "Карточки"},
      {key: "salePrice", sortable: true, label: "Цена/Доход"},
      {key: "qty", sortable: true, label: "Остатки"},
      {key: "rating", sortable: true, label: "Рейтинг"},
      {key: "position", sortable: true, label: "Место в выдаче"},
      {key: "sales", sortable: true, label: "Продажи"}
    ]
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getBgRowClass: function(art_wb) {
      if (art_wb == this.artwb) return 'font-weight-bold'

    },
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    },
    beforeDestroy() {
      this.controller.abort();
    },
    getGaysForArt() {
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-gays/${this.artwb}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.isLoading=false
              this.gays=resp.items
                  .filter(e=>{
                    if (e.art_wb==this.artwb) return true
                    return e.qty.fbo+e.qty.fbs>0
                  })
                  .map(e=>{
                if (e.art_wb==this.artwb) return {...e, _rowVariant:'success'}
                if (e.art_wb==this.artwb && e.qty.fbo+e.qty.fbs<=0) return {...e, _rowVariant:'danger'}
                 return {...e}
              })

            }
            if (!resp.status) this.alertMsg('Загружаем конкурентов | Ошибка', `ошибка загрузки конкурентов ${resp.message}`, 'danger')
          })
          // .catch(err => this.alertMsg('Загружаем конкурентов | Ошибка', `ошибка сервера ${err}`, 'danger'))
    },
    // getBgClass: function(value) {
    //   if (value > 0) return 'bg-success'
    //   if (value <= 0) return 'bg-danger'
    // }
    findMinPrice(cur, all) {
      if (cur==all.sort()[0]) return true
      return false
    }
  },
  beforeDestroy() {
    this.controller.abort();
  },
  computed: {
    // dohod: function () {
    //   return  Math.ceil(this.salePrice - (this.salePrice/100)*this.komsaPercent - this.zakup-55)
    // }
  },
  mounted() {
    this.controller = new AbortController();
    this.signal = this.controller.signal;

    this.isLoading = true
    this.getGaysForArt()
  }
}
</script>