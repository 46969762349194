<template>
    <b-row>
      <b-col lg="12">
        <b-row class="mt-2 mb-2">
          <b-col md="4">
            <pp-main-widget-price-file />
          </b-col>
          <b-col lg="4">
            <pp-main-widget-upload-promo-file />
          </b-col>
          <b-col lg="12">
            <pp-charts :art_wb="'hui'" />
          </b-col>
<!--          <b-col md="4">-->
<!--            <b-overlay :show="isLoadingStat" rounded="lg">-->
<!--              <b-list-group @click="getStats()">-->
<!--                <b-list-group-item href="#"   class="flex-column align-items-start">-->
<!--                  <div class="d-flex w-100 justify-content-between">-->
<!--                    -->

<!--                    <small>{{ statsUpd }} </small>-->
<!--                  </div>-->
<!--                  <h5 class="mb-1">всего: {{ stats.vip }}, с остатками: {{ stats.vipOnSale }}</h5>-->
<!--                  карточек WB: {{ stats.wb }}, транслируем: {{stats.wbOnSale}} <br>-->
<!--                  сейчас на WB: all: {{stats.wbFactip+stats.wbFactooo+stats.wbFactvipl}}, ip:{{stats.wbFactip}}, ooo:{{stats.wbFactooo}}, vipl:{{stats.wbFactvipl}}-->
<!--                </b-list-group-item>-->
<!--              </b-list-group>-->
<!--            </b-overlay>-->
<!--          </b-col>-->
        </b-row>
        <b-row>
          <pp-main-filter-panel
              @errfilter="loadDataloadDataErrFilter($event)" @seltype="loadData($event)" @selbrand="selBrand=$event"  @selseller="selSeller=$event" @ispromo="loadData($event)"
              :isLoading="isLoading" :all-brands="allBrands" />
        </b-row>
      </b-col>

      <div class="col-12 div-table">
        <b-table
            fixed
            bordered
            sticky-header
            small
            head-variant="light"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :items="filterModel"
            :fields="fields"
            :busy="isLoading"
            :tbody-tr-class="rowClass"
            :current-page="currentPage"
            :per-page="perPage"
            stacked="md"
            caption-top
        >
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style=calcColWidth(field.key)
            >
          </template>

          <template #table-caption>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                size="sm"
                class="my-0"
                align="right"
            ></b-pagination>
          </template>

          <template #table-busy>
            <div class="text-center">
              <b-spinner variant="primary" label="loading..."></b-spinner>
            </div>
          </template>
          <template #cell(name)="row">
           <nobr>
             <b-badge class="mr-1" variant="info" v-if="row.item.seller==='ip'">{{row.item.seller}}</b-badge>
            <b-badge class="mr-1" variant="primary" v-else-if="row.item.seller==='ooo'">{{row.item.seller}}</b-badge>
            <b-badge class="mr-1" variant="warning" v-else-if="row.item.seller==='vipl'">{{row.item.seller}}</b-badge>
            <b-badge class="mr-1" variant="danger" v-else>{{row.item.seller}}</b-badge>

            <a href="#" @click.prevent="row.toggleDetails">{{ row.item.model }} ({{row.item.allQtyCard}})

              <b-icon-chevron-up v-if="row.detailsShowing"/>
              <b-icon-chevron-down v-else/>
            </a>
           </nobr>
          </template>
          <template #cell(prices)="row">
<pp-main-prices :item="row.item" :key="`allprice0-${row.item.model}`" />
          </template>
          <template #cell(gays)="row">
<pp-main-gays-model :item="row.item" :key="`allgays0-${row.item.model}`" />
          </template>


          <template #row-details="row">
            <pp-main-price-form :arts="row.item.arts" :zakup="row.item.arts[0].zakup" :key="'dohod-'+row.item.model" />
          </template>


        </b-table>
      </div>
    </b-row>

</template>
<style scoped>
button:disabled {
  cursor: not-allowed;
  opacity: .5;
}
input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
<script>
import _ from "lodash";
// import DohodAndPrice from "@/components/products/cols/DohodAndPrice";
import PpMainFilterPanel from "@/ProductsAndPrice/PPmain-filterpanel";
import PpMainPriceForm from "@/ProductsAndPrice/PPmain-priceform";
import PpMainPrices from "@/ProductsAndPrice/PPmain-prices";
import PpMainWidgetPriceFile from "@/ProductsAndPrice/widget/PPmain-widget-pricefile";
import PpCharts from "@/ProductsAndPrice/chrts/PPCharts";
import PpMainGaysModel from "@/ProductsAndPrice/PPmain-gays";
import PpMainWidgetUploadPromoFile from "@/ProductsAndPrice/widget/PPmain-widget-uploadpromo";
export default {
  name: "pp-main",
  components: {
    PpMainWidgetUploadPromoFile,
    PpMainGaysModel, PpCharts, PpMainWidgetPriceFile, PpMainPrices, PpMainPriceForm, PpMainFilterPanel},
  props: [],
  data: () => ({
    totalRows: 0,
    selModels: [],
    models: [],
    allTypes: [
      'Все',
      'Прозрачные',
      'Цветные',
      'Мультифокальные',
      'Астигматические',
      'Растворы',
      'Остальное',
    ],
    selType:'',
    selTypes: [],
    allBrands: [],
    selBrand: '',
    selSeller: '',
    currentPage: 1,
    perPage: 100,
    isLoading: false,
    isLoadingStat: true,
    filter: '',
    filterOn: ['attr', 'name'],
    products: [],
    statsUpd: '',
    promoPrice: [],
    stats: {
      vip: 0,
      vipOnSale: 0,
      wb: 0,
      wbOnSale: 0,
      queueCreated: 0,
      queueError: 0,
      wbFactip: 0,
      wbFactooo: 0,
      wbFactvipl: 0,
    },
    fields: [
      {key: "name", sortable: true, label: "Товар"},
      {key: "prices", sortable: true, label: "Цены и доход"},
      {key: "gays", sortable: true, label: "Конкуренты"},
      {key: "sales", sortable: true, label: "Продажи"},
      // {key: "actions", sortable: false, label: ""},
    ],
    sorters: {
  qty: false}
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    setAll({model, salePrice, dohod}) {
       this.products.filter(el => el.model ===model).forEach(el => {
         el.arts.forEach(els =>{
           els.salePrice=salePrice
           els.dohod=dohod
         })
       })
    },
    // setToWbAllModel({model, salePrice, dohod, seller}) {
    //
    // },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "yes") return "table-success";
      if (item.status === "up") return "table-info";
    },
    async loadData({selType, isPromo}) {
      this.isLoading = true
      this.selBrand = ''
      // console.log(selType)
      // console.log(isPromo)
      fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-list/${selType}/${isPromo}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
          .then(resp => resp.json())
          .then(data => {
            if (!data.status) this.alertMsg('Загружаем список товаров | Ошибка', `ошибка сервера ${JSON.stringify(data.message)}`, 'danger')
            if (data.items.length <= 0) throw "нет товаров";

            const vip = _.chain(data.items)
                .groupBy("smodel")
                .map((value, smodel) => ({
                  model: value[0].model,
                  smodel: smodel,
                  type: value[0].type,
                  allQtyCard: value.length,
                  arts: value,
                  seller: value[0].seller,
                  brand: value[0].brand,
                }))
                .value()

            this.products = _.sortBy(vip.reverse(), 'model', 'desc')
            this.allBrands = Array.from(new Set(this.products.map(e=>e.brand)))

            this.totalRows = this.filterModel.length
            this.currentPage = 1
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
             this.alertMsg('Загружаем цену | Ошибка', `ошибка сервера ${error}`, 'danger')
            this.products = []
          });
    },
    calcColWidth(key) {
      if (key === 'name') return "width:300px"
      if (key === 'prices') return "width:300px"
      if (key === 'gays') return "width:300px"
      if (key === 'sales') return "width:120px"
      // if (key === 'actions') return "width:40px"
      // return "{ width: field.key === 'sales' ? '120px' : '180px' }"
      // return "width:80px"
    },
    onFiltered(filteredItems) {
      // console.log('on filter', filteredItems.length)
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    recalcTotalRows() {
      this.totalRows = this.filterModel.length
      this.currentPage = 1
    },
    async getStats() {
      this.isLoadingStat=true
      fetch(`${process.env.VUE_APP_SERVER_API}/report/getStats`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
          .then(resp => resp.json())
      .then(resp => {
        if (resp.status=='ok') {
          this.stats = resp.data
          this.statsUpd = resp.update
          this.isLoadingStat=false
        }
      })
    },
    async loadDataloadDataErrFilter(errType) {
      this.isLoading = true
      this.selBrand = ''
      fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-errfilter/${errType}`, {
        method: 'GET',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")}})
          .then(resp => resp.json())
          .then(data => {
            if (!data.status) this.alertMsg('Загружаем список товаров | Ошибка', `ошибка сервера ${JSON.stringify(data.message)}`, 'danger')
            if (data.items.length <= 0) throw "нет товаров";

            const vip = _.chain(data.items)
                .groupBy("smodel")
                .map((value, smodel) => ({
                  model: value[0].model,
                  smodel: smodel,
                  type: value[0].type,
                  allQtyCard: value.length,
                  arts: value,
                  seller: value[0].seller,
                  brand: value[0].brand,
                }))
                .value()

            this.products = _.sortBy(vip.reverse(), 'model', 'desc')
            this.allBrands = Array.from(new Set(this.products.map(e=>e.brand)))

            this.totalRows = this.filterModel.length
            this.currentPage = 1
            this.alertMsg('Загружаем | плохой рейтинг', `${data.message}`, 'success')

            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.alertMsg('Загружаем цену | Ошибка', `ошибка сервера ${error}`, 'danger')
            this.products = []
          });
    },
  },
  async mounted() {
    const dildoToken = this.$cookies.get("dildo-token");
    if (!dildoToken) this.$router.push({path: "/login"});

    this.getStats()
    setInterval(() => this.getStats(), 1200000)
    this.loadData({selType:'top', isPromo:false})

  },
  computed: {
    availableOptions() {
      return this.models.filter(opt => this.products.indexOf(opt) === -1)
    },
    filterModel() {
      // console.log(this.selBrand)
      let pr = this.products
      // if (this.selBrand.length==0) return pr
      if (this.selSeller) pr = pr.filter(e=> e.seller==this.selSeller)
      if (this.selBrand) pr = pr.filter(e=> e.brand==this.selBrand)

      return pr//.filter(e=> e.seller==this.selSeller)
    },
  },
  watch: {

  }

};
</script>

<style>
option {
  font-size: 10px;
}
.table-cur {
  background-color: rgba(183, 217, 254, 0.5) !important;
}
.table-primary {
  background-color: rgba(183, 255, 200, 0.4) !important;
}
.table-sec {
  background-color: rgba(255, 217, 254, 0.45) !important;
}

.div-table {
  font-size: 12px !important;
}

/*new*/
/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}
.custom-control-label {
  padding-top: 5px;
}
.form-control-sm{ padding: 0.25rem 0.5rem;
  font-size: 1.2rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
</style>
