<template>
  <b-row v-if="!isLoading">
    <b-col v-if=" this.items.length>0">
      Всего карточек : {{ this.items.length }}
    </b-col>

  </b-row>
  <b-row v-else>
    <b-col>
      <b-skeleton></b-skeleton>
<!--      <b-skeleton></b-skeleton>-->
    </b-col>
  </b-row>
</template>
<style scoped>
.sales_value {
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 0px;
  margin: 0px;
  width: 30px;
  text-align: center;
  cursor: default;
}
</style>
<script>
export default {
  name: 'pp-main-gays-model',
  props: ["item"],
  data: () => ({
    isLoading: false,
    items: [],
    onPromo: -1,
    allSellers: -1,
      minPrice: 1800,
      maxPrice: 2000
  }),
  methods: {
    getGaysForAllArts() {
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-gays-many/${this.item.arts.map(e=>e.art_wb).join(";")}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) {
              this.isLoading=false
              this.items = resp.items
              // this.onPromo = resp.items.reduce((t, {curPromo}) => (curPromo) ? t++ : t,0)
              // this.allSellers = Array.from(new Set(resp.items.map(e=> e.seller))).length
              // this.minPrice = Math.min(...resp.items.map(e=>e.salePrice))
              // this.maxPrice = Math.max(...resp.items.map(e=>e.salePrice))
            }
            // if (!resp.status) this.alertMsg('Загружаем конкурентов | Ошибка', `ошибка загрузки продаж ${JSON.stringify(resp.message)}`, 'danger')
          })
          // .catch(err => this.alertMsg('Загружаем продажи | Ошибка', `ошибка сервера ${JSON.stringify(err)}`, 'danger'))
    }
  },
  beforeDestroy() {
    this.controller.abort();
  },
  mounted() {
    this.controller = new AbortController();
    this.signal = this.controller.signal;
    // if (this.item.brand=='Acuvue') {
      this.isLoading = true
      this.getGaysForAllArts()
    // }
  }
}
</script>