<template>
  <b-row v-if="!isLoading">
    <b-col>
      <b-row>
        <b-col sm="4" class="pr-0"><b-form-input v-model="salePrice" type="number" title="Цена на сайте"></b-form-input></b-col>
        <b-col sm="3" class="pr-0 pl-0"><b-form-input disabled type="number" v-model="dohod" :title="`${dohod} (доход) = ${salePrice} (цена на сайте) - ${Math.ceil((this.salePrice/100)*this.komsaPercent)} (комса wb ${komsaPercent}%) - ${item.arts[0].zakup} (закуп) - 55 (логистика)`"></b-form-input></b-col>
        <b-col sm="2" class="pr-0 pl-0"><b-form-input  type="number" v-model="komsaPercent" title="% комсы вб"></b-form-input></b-col>
        <b-col sm="3" class="pr-0 pl-1">
          <b-icon-cash class="mr-1" variant="danger" animation="throb" :title="`Есть артикулы с доходом <${MIN_DOHOD} руб.`"
v-if="veryMinPrice>0"
          />
         <nobr><b-icon v-if="wb.reduce((t, {curPromo}) => t+curPromo.slice(0,1).length,0)>0" icon="x-diamond-fill" variant="success" :title="'В акции артикулов: ' + wb.reduce((t, {curPromo}) => t+curPromo.slice(0,1).length,0) + ' из ' + wb.length"></b-icon> </nobr>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-badge href="#" size="sm" variant="success" @click="setToWbAllModel()" class="mr-2" v-if="artsOnQueue==0">Применить</b-badge>
          {{Array.from(new Set(wb.map(e=>e.salePrice)))}}
          <span v-if="artsOnQueue>0">ждут проверки цен: {{artsOnQueue}}</span>
          <br>
          <b-badge href="#" size="sm" variant="info" @click="setPromoAll(0)" class="mr-2" v-if="wbPromo.length>0 && artsOnQueue==0">Промо для всех ({{wbPromo.length}})</b-badge>
<!--          <b-badge href="#" size="sm" variant="info" @click="setPromoAll()" class="mr-2" v-if="wbPromo.filter(e=>e.promoDohod>=MIN_DOHOD).length>0">Промо доход>{{MIN_DOHOD}} ({{wbPromo.filter(e=>e.promoDohod>=MIN_DOHOD).length}})</b-badge>-->
          <b-badge href="#" size="sm" variant="info" @click="setPromoAll(100)" class="mr-2" v-if="wbPromo.filter(e=>e.promoDohod>=100).length>0 && artsOnQueue==0">Промо доход>100 ({{wbPromo.filter(e=>e.promoDohod>=100).length}})</b-badge>
<!--          <b-badge href="#" size="sm" variant="warning" @click="setPromoAll()" class="mr-2" v-if="wbPromo.filter(e=>e.promoDohod>=100).length>0">Поднять цены для всех ({{wbPromo.filter(e=>e.promoDohod>=100).length}})</b-badge>-->
<!--        promo dohod: {{wbPromo.map(e=>e.promoDohod)}}-->
        </b-col>
      </b-row>
    </b-col>

  </b-row>
  <b-row v-else>
    <b-col>
      <b-skeleton></b-skeleton>
    </b-col>
  </b-row>

</template>
<script>
export default {
  name: 'pp-main-prices',
  props: ["item"],
  data: () => ({
    salePrice: 0,
    komsaPercent: 19,
    MIN_DOHOD: 300,
    wbPromo: [],
    artsOnQueue: 0,
    wb: [],
    isLoading: true
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    getBgClass: function(value) {
      if (value > 0) return 'bg-success'
      if (value <= 0) return 'bg-danger'
    },
    setPromoAll(minDohod) {
      fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-price/arts_wb`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({arts: this.wbPromo.filter(e=>e.promoDohod>=minDohod).map(e=>({art_wb: +e.art_wb, salePrice: e.promoPrice, seller: this.item.seller, status: 'created'}))}),
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.alertMsg('Загружаем цены | Успешно', `Добавлено в очередь: ${resp.items.length} артикулов`, 'success')
            if (!resp.status) this.alertMsg('Загружаем цены | Ошибка', 'что-то пошло не так =(', 'error')
          })
    },
    setToWbAllModel() {
      const {model, seller} = this.item

      fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-price/model`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        body: JSON.stringify({model, salePrice:this.salePrice, seller}),
        signal: this.controller.signal
      })
          .then(resp => {
            return resp.json()
          })
          .then(({status}) => {
            if (status) this.alertMsg('Загружаем цены | Успешно', 'Новые цены в очереди на заливку на вб', 'success')
            if (!status) this.alertMsg('Загружаем цены | Ошибка', 'ошибка сервера', 'danger')
          })
          .catch(err => {
            this.alertMsg('Загружаем цены | Ошибка', `ошибка загрузки на сервер: ${err}`, 'danger')
          })
    },
    getWbForOpenedArts() {
      let arts = this.item.arts.map(el=>el.art_wb).join(";")
      if (this.item.arts.length>500) arts = this.item.arts.slice(0,500).map(el=>el.art_wb).join(";")

      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-info/wb/${arts}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.wb=resp.items
            if (!resp.status) this.alertMsg('Загрузка с WB | Ошибка', `Не удалось загрузить остатки и цены товаров на вб\n${resp.message}`, 'danger')
          })
          .catch(err=>this.alertMsg('Загрузка с WB | Ошибка', `Ошибка сервера \n${err}`, 'danger'))
    },
    getPromoWbForOpenedArts() {
      let arts = this.item.arts.map(el=>el.art_wb).join(";")
      if (this.item.arts.length>500) arts = this.item.arts.slice(0,500).map(el=>el.art_wb).join(";")
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-info/wb-promo/${arts}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.wbPromo=resp.items.map(e=>{
              e.promoDohod = Math.ceil(e.promoPrice - (e.promoPrice/100)*e.promoKomsa - this.item.arts[0].zakup-55)
              return e
            })
            if (!resp.status) this.alertMsg('Загрузка промо с WB | Ошибка', `Не удалось загрузить промо цены для товаров на вб\n${resp.message}`, 'danger')
          })
          .catch(err=>this.alertMsg('Загрузка промо с WB | Ошибка', `Ошибка сервера \n${err}`, 'danger'))
    },
    getOnQueueForOpenedArts() {
      let arts = this.item.arts.map(el=>el.art_wb).join(";")
      if (this.item.arts.length>500) arts = this.item.arts.slice(0,500).map(el=>el.art_wb).join(";")
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-info/onqueue/${arts}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.artsOnQueue=resp.items
            if (!resp.status) this.alertMsg('Загрузка артикулов в очереди | Ошибка', `Не удалось загрузить кол-во артикулов в очереди`, 'danger')
          })
          .catch(err=>this.alertMsg('Загрузка артикулов в очереди | Ошибка', `Ошибка сервера \n${err}`, 'danger'))
    },
  },
  mounted() {
    this.controller = new AbortController();
    this.signal = this.controller.signal;

    this.getWbForOpenedArts().then(()=>{
      this.salePrice = Math.min(...this.wb.map(e=>e.salePrice))
      this.isLoading = false
    })
    this.getPromoWbForOpenedArts()
    this.getOnQueueForOpenedArts()
  },
  computed: {
    dohod: function () {
      return  Math.ceil(this.salePrice - (this.salePrice/100)*this.komsaPercent - this.item.arts[0].zakup-55)
    },
    veryMinPrice: function () {
     return this.wb.reduce((t, {salePrice}) => {
        if(Math.ceil(salePrice - (salePrice/100)*this.komsaPercent - this.item.arts[0].zakup-55)<=this.MIN_DOHOD) return t=t+1
       return t
      },0)
    }
  },
  beforeDestroy() {
    this.controller.abort();
  },
  watch: {
  }
}
</script>

<style scoped>
input[type="text"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 10px;
  font-weight: 800;
  line-height: 1.1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="number"] {
  padding: 2px;
  height: 20px;
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef!important;
  opacity: 1;
}
</style>