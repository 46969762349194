var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',[_c('b-table',{attrs:{"items":_vm.gays,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"responsive":"sm","busy":_vm.isLoading},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"variant":"primary","label":"loading..."}})],1)]},proxy:true},{key:"cell(seller)",fn:function(ref){
var item = ref.item;
return [_c('a',{class:_vm.getBgRowClass(item.art_wb),attrs:{"href":'https://www.wildberries.ru/catalog/' + item.art_wb + '/detail.aspx?targetUrl=XS',"target":"_blank"}},[_vm._v(" "+_vm._s(item.seller)+" "),_c('b-icon',{attrs:{"icon":"box-arrow-up-right"}})],1)]}},{key:"cell(salePrice)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"14px"},attrs:{"title":"Цена на сайте (доход с учётом нашей закупки и комсы 10%)"}},[_vm._v(_vm._s(item.salePrice)+" ("+_vm._s(Math.ceil(item.salePrice - (item.salePrice/100)*10 - _vm.zakup-55))+")")]),(_vm.findMinPrice(item.salePrice, _vm.gays.map(function (e){ return e.salePrice; })))?_c('b-badge',{staticClass:"ml-1",staticStyle:{"cursor":"pointer"},attrs:{"variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('setmin', item.salePrice)}}},[_vm._v("Мин. цена")]):_vm._e()]}},{key:"cell(qty)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-white pl-1 pr-1 mr-1",class:_vm.getBgClass(item.qty.fbo),attrs:{"title":"Остатки на складе вб"}},[_vm._v(_vm._s(item.qty.fbo))]),_c('span',{staticClass:"text-white pl-1 pr-1 mr-2",class:_vm.getBgClass(item.qty.fbs),attrs:{"title":"Транслируют на вб"}},[_vm._v(_vm._s(item.qty.fbs))])]}},{key:"cell(rating)",fn:function(ref){
var item = ref.item;
return [(item.feedbackCount > 0)?_c('span',[_vm._l((item.rating),function(r){return _c('b-icon-star-fill',{key:'key-1'+r})}),_vm._l((5-item.rating),function(r){return _c('b-icon-star',{key:'key-12'+r})}),_vm._v(" "+_vm._s(item.feedbackCount)+" ")],2):_c('span',[_vm._v(" нет отзывов ")])]}},{key:"cell(position)",fn:function(){return [_vm._v(" 0 ")]},proxy:true},{key:"cell(sales)",fn:function(ref){
var item = ref.item;
return [(item.sales.length>0)?_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"flex-start","border":"0px solid #f0f"}},_vm._l((item.sales.slice(0,7)),function(ofDate){return _c('div',{key:ofDate.date+item.art_wb,staticClass:"sales_value"},[_c('span',{staticClass:"text-monospace",attrs:{"title":((ofDate.date) + "\nПродаж: " + (ofDate.sales) + "\nНа сумму: " + (ofDate.sales*item.salePrice) + "\nДоход: " + (Math.ceil((item.salePrice - (item.salePrice/100)*10 - _vm.zakup-55)*ofDate.sales)))}},[_vm._v(" "+_vm._s(ofDate.sales))])])}),0):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }