<template>
    <b-row>
      <b-col xl="12" class="mb-2">
        <b-button size="sm" variant="outline-primary"
                  v-for="(af,indx) of acitveFilters"
                  :key="'btn-filter-'+indx"
                  class="mr-1 mb-1"
                  :class="{'active':af.status}"
                  @click.prevent="af.status=!af.status"
        >{{af.text}}</b-button>
        {{filteredArts.length}} из {{arts.length}}
 </b-col>
      <b-col xl="12">
        <b-table
            small
            :items="filteredArts"
            :fields="fields"
            thead-class="hidden"
        >
          <template #table-colgroup="scope">
            <col
                v-for="field in scope.fields"
                :key="field.key"
                :style=calcColWidth(field.key)
            >
          </template>
          <template #cell(vipName)="{item}">
            <b-row v-if="!isLoading" >
              <b-col>
                <pp-main-price-form-row :key="'rowFor-' + item.art_wb" :item="item" :wb="wb.find(el=>el.art_wb==item.art_wb)" :vip="vip.find(el=>el.art_wb==item.art_wb)"/>
              </b-col>
            </b-row>
           <b-row v-else>
              <b-col><b-skeleton height="18px" animation="throb"></b-skeleton></b-col>
            </b-row>

          </template>
        </b-table>
      </b-col>
    </b-row>
</template>
<style>
.hidden {
  display: none;
}
.table {
  margin-bottom: 5rem;
}
.b-table-sticky-header, .table-responsive, [class*=table-responsive-] {
  margin-bottom: 0rem;
}
</style>
<script>
import PpMainPriceFormRow from "@/ProductsAndPrice/PPmain-priceform-row";

export default {
  props: ["arts"],
  components:{ PpMainPriceFormRow },
  name: "pp-main-price-form",
  data: () => ({
    curShow: "",
    fields: [
      {key: "vipName", sortable: true, label: "Товар"}
    ],
    komsaPercent: 10,
    wb: [],
    vip: [],
    isLoading: true,
    acitveFilters: [{text:'-', status: false, key:'dio'}, {text:'+', status: false, key:'dio'}]
  }),
  methods: {
    alertMsg(title, message, style, append = false) {
      this.$bvToast.toast(`${message}`, {
        title: `${title}`,
        toaster: 'b-toaster-top-right',
        variant: style,
        solid: true,
        appendToast: append
      })
    },
    priceClass(conc, item) {
      try {
        if (conc.qty+conc.qtyInWh<=0) return 'light'
        if (conc.salePrice>item.salePrice) return 'light'
        if (conc.salePrice<=item.salePrice) return 'danger'
      }catch (err) {
        return {'text-info': true}
      }

    },
    calcColWidth(key) {
      if (key === 'salePrice') return "width:100px"
      if (key === 'vipName') return "width:150px"
      if (key === 'gays') return "width:470px"
      if (key === 'sales') return "width:100px"
      // return "{ width: field.key === 'sales' ? '120px' : '180px' }"
      // return "width:80px"
    },
    getArtClass: function(value) {
      if (value>0) return 'text-success'
      if (value<=0) return 'text-danger'
    },
    getWbForOpenedArts() {
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-info/wb/${this.arts.map(el=>el.art_wb).join(";")}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.wb=resp.items
            if (!resp.status) this.alertMsg('Загрузка с WB | Ошибка', `Не удалось загрузить остатки и цены товаров на вб\n${resp.message}`, 'danger')
          })
      .catch(err=>this.alertMsg('Загрузка с WB | Ошибка', `Ошибка сервера \n${err}`, 'danger'))
    },
    getVipForOpenedArts() {
      return fetch(`${process.env.VUE_APP_SERVER_API}/pp/pp-info/vip/${this.arts.map(el=>el.art_wb).join(";")}`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json', 'dildo-token': this.$cookies.get("dildo-token")},
        signal: this.controller.signal
      })
          .then(resp => resp.json())
          .then(resp => {
            if (resp.status) this.vip=resp.items
            if (!resp.status) this.alertMsg('Загрузка с VIP | Ошибка', `Не удалось загрузить остатки на нашем складе\n${resp.message}`, 'danger')
          })
      .catch(err=>this.alertMsg('Загрузка с VIP | Ошибка', `Ошибка сервера \n${err}`, 'danger'))
    }
  },
  mounted() {
    this.controller = new AbortController();
    this.signal = this.controller.signal;

    this.getWbForOpenedArts().then(()=>this.isLoading=false)
    this.getVipForOpenedArts() //.then(()=>this.isLoading=false)

    // const alldio = Array.from(new Set(this.arts.map(e=>e.dio)))
    const allradius = Array.from(new Set(this.arts.map(e=>e.radius)))
    const allcolor = Array.from(new Set(this.arts.map(e=>e.color)))
    const alldia = Array.from(new Set(this.arts.map(e=>e.dia)))
    const alladida = Array.from(new Set(this.arts.map(e=>e.adida)))
if(allradius.length>1) allradius.forEach(e=>this.acitveFilters.push({text: e, status: false, key:'radius'}))
if(allcolor.length>1) allcolor.forEach(e=>this.acitveFilters.push({text: e, status: false, key:'color'}))
if(alldia.length>1) alldia.forEach(e=>this.acitveFilters.push({text: e, status: false, key:'dia'}))
if(alladida.length>1) alladida.forEach(e=>this.acitveFilters.push({text: e, status: false, key:'adida'}))

  },
  beforeDestroy() {
    this.controller.abort();
  },
  computed: {
    filteredArts: function() {
      let fP = this.arts
      const curFilter = this.acitveFilters.filter(e=>e.status) //.filter(e=>e.key!='qty')
      curFilter.forEach(filter=>{
          fP = fP.filter(e=>e[filter.key].indexOf(filter.text)+1)
      })


      console.log(fP)

      return fP
    }
  }
}
</script>